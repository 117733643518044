import React from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { isDummyRender } from "../../redux/Slices/startFullTestSlice";


const ModuleOneInstructions = () => {

   const dispatch = useDispatch();

    const closeDialogHandler = () => {
      dispatch(closeDialog());
      dispatch(isDummyRender(false))
    }

  return (
    <div className="flex flex-col ">
      <div className="flex flex-col leading-6 h-96 ">
        <p className="py-6 text-[#202020] text-lg">
          The questions in this section address a number of important reading
          and writing skills. Each question includes one or more passages, which
          may include a table or graph. Read each passage and question
          carefully, and then choose the best answer to the question based on
          the passage(s).
        </p>
        <p className="text-[#202020] text-lg">
          All questions in this section are multiple-choice with four answer
          choices. Each question has a single best answer.
        </p>
      </div>
      <div className="flex justify-center">
        <CustomButton
          name="Close"
          onClickHandler={closeDialogHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-24"
          border="[#333]"
          py="py-4"
          round="full"
        />
      </div>
    </div>
  );
};

export default ModuleOneInstructions;
