let storage = localStorage;

export function setItem(key: string, value: string) {
  return storage.setItem(key, value);
}

export function getItem(key: string) {
  return storage.getItem(key);
}

export function clearStore() {
  storage.clear();
}

export function removeItem(key: string) {
  return storage.removeItem(key);
}