import  { useMemo } from 'react';
import TeX from "@matejmazur/react-katex";

const cleanInputString = (input:any) => {
  return input.replace(/[\u200B-\u200D\uFEFF]/g, '').replace(/\s+/g, ' ').trim();
};
const extractLatexParts = (cleanedString:any) => {
  const latexPattern = /\$\~\s*(.*?)\s*\$\~/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = latexPattern.exec(cleanedString)) !== null) {
    if (match.index > lastIndex) {
      parts.push(cleanedString.slice(lastIndex, match.index));
    }
    parts.push(<TeX key={crypto.randomUUID()}>{match[1]}</TeX>);
    lastIndex = latexPattern.lastIndex;
  }

  if (lastIndex < cleanedString.length) {
    parts.push(cleanedString.slice(lastIndex));
  }

  return parts;
};

const processImageParts = (part:any, imageDetails:any, isOptions:any) => {
  const imgPattern = !isOptions ? /\{#(.*?)#\}/g : /\{(.*?)\}/g;
  const processedParts = [];
  let lastImgIndex = 0;
  let imgMatch;

  while ((imgMatch = imgPattern.exec(part)) !== null) {
    if (imgMatch.index > lastImgIndex) {
      processedParts.push(part.slice(lastImgIndex, imgMatch.index));
    }
    const imgSrc = imageDetails?.[imgMatch[1]];
    processedParts.push(
      imgSrc ? <img key={crypto.randomUUID()} src={imgSrc} alt="" /> : `{#${imgMatch[1]}#}`
    );
    lastImgIndex = imgPattern.lastIndex;
  }

  if (lastImgIndex < part.length) {
    processedParts.push(part.slice(lastImgIndex));
  }

  return processedParts;
};
const processPlaceholders = (subParts:any) => {
  return subParts.map((subPart:any) =>
    typeof subPart === 'string' ? subPart.split(':exp{}:exp').map((strPart, strIndex) =>
      strIndex > 0 ? [<span key={crypto.randomUUID()}>________</span>, strPart] : strPart
    ).flat() : subPart
  ).flat();
};
const processString = (inputString:any, imageDetails:any, isOptions:boolean) => {
  const cleanedString = cleanInputString(inputString);
  const parts = extractLatexParts(cleanedString);

  return parts.map((part) => {
    if (typeof part === 'string') {
      const processedParts = processImageParts(part, imageDetails, isOptions);
      return processPlaceholders(processedParts);
    }
    return part;
  }).flat();
};

const RenderText = ({ text, imageDetails = {}, isOptions }:any) => {
  // Memoize the processed text to prevent unnecessary re-renders
  const processedText = useMemo(() => processString(text, imageDetails.imageDetails || {}, isOptions), [text, imageDetails, isOptions]);

  return <div>{processedText}</div>;
};

export default RenderText;
