import { createSlice } from "@reduxjs/toolkit";

interface Loader{
    isLoading:boolean,
    isError:boolean,
    error:string
}

const initialState ={
isLoading :false,
isError:false,
error:''
}

const loaderSlice = createSlice({
    name:"loader",
    initialState,
    reducers:{
        setLoader:(state,action) => {
            state.isLoading = action.payload.isLoading;
            state.isError = action.payload.isError;
            state.error= action.payload.error 
        }
    }
})

export const {setLoader} = loaderSlice.actions;
export default loaderSlice.reducer