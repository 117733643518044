import React from "react";

const CustomButton = ({
  name,
  onClickHandler,
  width = "w-full",
  font = "font-medium",
  bg = "bg-primaryColor",
  color = "white",
  round="md",
  text="text-base",
  py="py-2",
  border="border-transparent",
  hover="hover:bg-black",
  mx="mx-0",
  disabled,
}: {
  name: string;
  onClickHandler: () => void;
  width?: string;
  font?: string;
  bg?: string;
  color?: string;
  round?: string;
  text?:string;
  py?:string;
  border?:string;
  hover?:string;
  mx?:string;
  disabled?:boolean;
}) => {
  return (
    <>
      {/*if u want to  use focus use like focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2  */}
      <button
        type="button"
        onClick={onClickHandler}
        className={`inline-flex ${width}  ${font} justify-center border 
        ${border} ${bg} px-4 md:${py} py-1 ${color} ${text} focus:outline-none  text-${color} ${hover} hover:text-white
        disabled:opacity-50 disabled:pointer-events-none rounded-${round} ${mx} ${disabled? "bg-secondaryColor":""} `}
        disabled={disabled}
      >
        {name}
      </button>
    </>
  );
};

export default CustomButton;
