import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import QuestionsOverView from "./QuestionsOverView";

interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionTypeId: string;
  more: any; // You might want to replace 'any' with a more specific type
  additionalProps: any;
}

interface PopupProps {
  onClose: () => void;
  questionsList: ReadingModuleData[];
  userAnswers: Answers[];
  heading: () => React.ReactNode;
  count:number;
  isPopUp:boolean
}

const PopUp = React.memo(({ onClose, questionsList, userAnswers, heading,count,isPopUp }: PopupProps) => {

  return (
    <>
      {isPopUp && (
        <div className="absolute bottom-[160%] min-w-[40rem] bg-white  border border-[#e4e4e4] z-50 -left-[50%] rounded-lg">
          <div className="mx-10 flex py-5 relative">
            <div className="font-semibold mx-auto text-xl">
              {heading()}
            </div>
            <button
              className="text-gray-600 hover:text-gray-800 absolute right-0"
              onClick={onClose}
            >
              <IoCloseOutline size={30} />
            </button>
          </div>
         <QuestionsOverView questionsList={questionsList} userAnswers={userAnswers} count={count}  navigation={true} heading={heading}/>
        </div>
      )}
    </>
  );
},(prevProps,nextProps) => {
    return (
        prevProps.onClose === nextProps.onClose &&
        prevProps.questionsList === nextProps.questionsList &&
        prevProps.userAnswers === nextProps.userAnswers &&
        prevProps.heading() === nextProps.heading() &&
        prevProps.count === nextProps.count &&
        prevProps.isPopUp === nextProps.isPopUp
      );
});

export default PopUp;
