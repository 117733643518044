import CustomButton from "../components/ReUsableComponents/CustomButton";

export const next = (nexxtClickHandler:any) => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold"
          width="w-full"
          py="py-2"
          round="full"
          hover="hover:bg-primaryColor"
        />
      </div>
    );
  };