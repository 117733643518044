import React from 'react'
import { Link } from 'react-router-dom';
import ErrorImage from '../assets/Images/errorpage.gif'
import { useSelector } from 'react-redux';
import { selectFullTestState } from '../redux/Selectors/selectorsUse';

const ErrorPage = () => {
  const {loadingSlice} = useSelector(selectFullTestState);
  return (
    <div className='flex justify-center pt-16 text-[#484848]'>
        <div className='flex flex-col w-1/2 '>
            <div className='text-9xl font-bold '>Oops!</div>
            <div className='text-4xl pt-4'>We can't seem to find the page you're looking for.</div>
            <div className='text-sm font-bold py-4'>{loadingSlice.error}</div>
            <div className='text-base '>Here are some helpful links instead:</div>
            <div className='text-primaryColor font-semibold underline'>
            {loadingSlice.error === 'Un Authorised' ? <Link to='/login'> Log In</Link> : <Link to='/practices'>Home</Link>} 
              </div>
        </div>
        <div><img src={ErrorImage} alt='gif'/></div>
    </div>
  )
}

export default ErrorPage