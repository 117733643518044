import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from '@reduxjs/toolkit/query';


const customBaseQuery = (props: FetchBaseQueryArgs) => {
    const baseQuery = fetchBaseQuery({ ...props })
    const fn: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError
    > = async (args, api, extraOptions) => {

        api.dispatch({ type: 'loader/setLoader', payload: { isLoading: true, isError: false, error: '' } })
        const result = (await baseQuery(args, api, extraOptions)) as any;
        if (result?.data) {
            api.dispatch({ type: 'loader/setLoader', payload: { isLoading: false, isError: false, error: '' } })
        }
        if (result.error) {
            if (result.error.status !== 401) {
                api.dispatch({ type: 'loader/setLoader', payload: { isLoading: false, isError: true, error: `${result.error.status}:${result.error.data.title}` } })
            } else {
                api.dispatch({ type: 'loader/setLoader', payload: { isLoading: false, isError: true, error: `Un Authorised` } });
                localStorage.clear();
            }

        }
        setTimeout(() => {
        }, 400);
        return result;
    };
    return fn;

}

export default customBaseQuery