import React, { useEffect } from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import { changeCurrentModuleName, setCurrentTime } from "../../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import Timer from "../ReUsableComponents/Timer";
import {getItem} from '../../utils/token'


const ModuleBreakTime = () => {
  const dispatch = useDispatch();
  const currentTime = useSelector((state:RootState) => state.root.startFullTest.currentTime)

  useEffect(() => {
    dispatch(changeCurrentModuleName("moduleBreakTime"));
  }, []);

  useEffect(() => {
    if(currentTime <=0){
      dispatch(setCurrentTime(2100))
      dispatch(changeCurrentModuleName("mathModule1"));
    }
  },[currentTime])
  return (
      <div className="flex flex-col h-screen bg-[#333]">
        <div className="h-[7%]  pl-32 text-2xl flex text-white font-bold items-end">
          {getItem('currentTestName')}
        </div>
        <div className="flex-grow flex py-4">
          <div className="w-1/2  font-bold text-center flex flex-col justify-center items-center  rounded-lg">
            <div className="flex flex-col border-2 border-[#e4e4e4] rounded-2xl p-6 ">
              <div className="text-2xl text-white pb-4">
                Remaining Break Time
              </div>
              <div className="text-7xl text-white"><Timer /></div>
            </div>
            <div className="mt-8">
              <CustomButton
                name="Resume Testing"
                onClickHandler={() => {
                  dispatch(changeCurrentModuleName("mathModule1"));
                  dispatch(setCurrentTime(2100))
                }}
                font="font-bold"
                text="text-2xl"
                py="py-4"
                round="full"
                hover="hover:bg-primaryColor"
              />
            </div>
          </div>
          <div className="w-1/2 border-l-2 flex flex-col py-8 px-10 border-[#e4e4e4] leading-10">
            <div className="text-3xl py-6 font-bold text-white ">Practice Test Break</div>
            <div className="border-b-2 border-white py-4 text-white opacity-75">
              You can resume this practice test as soon as you're ready to move
              on. On test day, you'll wait until the clock counts down. Read
              below to see how breaks work on test day.
            </div>
            <div className="text-3xl py-6 font-bold text-white">Take a Break</div>
            <div className=" text-white opacity-75">
              You may leave the room, but do not disturb students who are still
              testing. Testing won't resume until you return.
            </div>
            <div className="text-lg font-bold py-6 text-white">
              Follow these rules during the break:
            </div>
            <div className="flex flex-col text-white opacity-75">
              <p>
                1. Do not access your phone, smartwatch, testbooks, notes, or
                the internet.
              </p>
              <p>2. Do not eat or drink in the test room. </p>
              <p>
                3. Do not speakin the test room; outside the test room, do not
                discuss the exam with anyone.
              </p>
            </div>
          </div>
        </div>
        <div className="h-[10%] border-t-2 border-[#e4e4e4] flex text-xl text-white font-bold items-center pl-32">
        {getItem('userName')}
        </div>
      </div>
  );
};

export default ModuleBreakTime;
