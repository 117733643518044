interface RightOverlayContentProps {
    isAnimated: boolean;
    setIsAnimated: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightOverlayContent: React.FC<RightOverlayContentProps> = ({ isAnimated, setIsAnimated }) => {
    return (
      <div className="p-8 text-center">
        <h1 className="text-[40px] font-bold text-white mb-4">New Here?</h1>
  
        <h5 className="text-[24px] text-white">
          Sign Up and discover a great amount of new opportunities!
        </h5>
        <div className="mt-16">
          <button
            className="py-3 px-6 border border-[#FEFEFE] bg-transparent rounded-md text-center text-white font-bold uppercase transition-transform ease-in"
            onClick={(e) => {
              setIsAnimated(!isAnimated);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    );
};

export default RightOverlayContent;



