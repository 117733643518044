import { createApi } from "@reduxjs/toolkit/query/react";
import { getItem } from "../../utils/token";
import customBaseQuery from "./customBaseQuery";
import {serviceUrl} from "../../apiConfig"

const api = createApi({
  baseQuery: customBaseQuery({
    baseUrl: serviceUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      const token = getItem("token");
      if (token) {
        console.log("TOKENAUTH");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default api;
