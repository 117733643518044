import { createSlice } from "@reduxjs/toolkit";

interface ModalState{
    isOpen:boolean,
    dailogType:string | null
}

const initialState:ModalState={
    isOpen:false,
    dailogType:null
}


const dialogSlice = createSlice({
    name:"dialog",
    initialState,
    reducers:{
        openDialog:(state,action) => {
            state.isOpen = true;
            state.dailogType = action.payload;
        },
        closeDialog:(state) => {
            state.isOpen = false;
            state.dailogType = null;
        }
    }
})

export const {openDialog,closeDialog} = dialogSlice.actions;
export default  dialogSlice.reducer