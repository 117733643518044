import React, { useCallback } from 'react'
import { useDispatch } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";
import { setCount } from '../../redux/Slices/startFullTestSlice';

interface Answers {
    qId: string;
    userAnswer: string | undefined;
    userOption: string;
  }

  interface Answer {
    isActive: boolean;
    answerOption: string;
    isCorrectAnswer: boolean;
    correctAnswer: string;
    answerPlaceHolder: string;
  }

interface ReadingModuleData {
    _id: string;
    isActive: boolean;
    description: string;
    question: string;
    explanation: string;
    moduleId: string;
    sectionId: string;
    answers: Answer[];
    questionTypeId: string;
    more: any; // You might want to replace 'any' with a more specific type
    additionalProps: any;
  }

interface QuestionOverView{
    questionsList: ReadingModuleData[];
    userAnswers:Answers[];
    count:number;
    navigation:boolean;
    heading: () => React.ReactNode;
}


const QuestionsOverView = ({questionsList,userAnswers,count, navigation,heading}:QuestionOverView) => {
    const dispatch = useDispatch();
    const navigateCurrentQuestion = useCallback((index: number) => {
        dispatch(setCount(index))
      }, [dispatch]);
  return (
    <div>
          <div className="flex justify-around px-6 py-4 border-y mx-10 my-1 border-[#e4e4e4]">
            
            {navigation ? <div className="flex items-center">
              <IoLocationOutline size={20} />
              Current
            </div> : heading()}
            <div className="flex items-center">
              <div className="w-4 h-4 mr-1 border border-dashed border-black"></div>
              Unanswered
            </div>
          </div>
          <div className="flex flex-wrap px-6">
            {questionsList?.map((question, index) => {
              let matchedQuestion = userAnswers.find(
                (item) => item.qId === question["_id"]
              );

              return (
                <div key={question._id}>
                  <button
                    className={`flex w-8 h-8 justify-center relative items-center border mx-2 my-3 font-semibold ${
                      matchedQuestion?.userAnswer
                        ? "bg-primaryColor border text-white"
                        : "border-dashed"
                    } border-black`}
                    onClick={() => navigateCurrentQuestion(index)}
                  >
                    {index + 1}
                    {count === index && (
                      <div className="flex justify-center absolute -top-[62%] text-black">
                        <IoLocationOutline size={20} />
                      </div>
                    )}
                  </button>
                </div>
              );
            })}
          </div>
    </div>
  )
}

export default QuestionsOverView