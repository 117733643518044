import { createSlice } from "@reduxjs/toolkit";

interface LoginState {
  createAccount: boolean;
  emailError:string;
  mobileNumberError:string;
}
const initialState: LoginState = {
  createAccount: false,
  emailError:"",
  mobileNumberError:""
};
const loginSlice = createSlice({
  name: "Store",
  initialState,
  reducers: {
    setCreateAccount: (state, action) => {
      state.createAccount = action.payload;
    },
    setEmailError:(state,action)=>{
      state.emailError = action.payload
    },
    setMobileNumberError:(state,action)=>{
      state.mobileNumberError = action.payload
    },
  },
});
export const { setCreateAccount,setEmailError,setMobileNumberError } = loginSlice.actions;
export default loginSlice.reducer;
