import api from '../createApi'

export const loginApi = api.injectEndpoints({
    endpoints: (builder) => ({
        signup :builder.mutation({
            query:(payload)=>({
               url:'/auth/signup',
               method:'POST',
               body:payload,
            })
        }),
        signIn:builder.mutation({
            query:(payload)=>({
                url:'/auth/login',
                method:"POST",
                body:payload
            })
        })
    })})
    export const {useSignupMutation,useSignInMutation} = loginApi