interface LeftOverlayContentProps {
    isAnimated: boolean;
    setIsAnimated: React.Dispatch<React.SetStateAction<boolean>>;
}
const LeftOverlayContent: React.FC<LeftOverlayContentProps> = ({ isAnimated, setIsAnimated }) => {
    return (
      <div className="p-8 text-center">
        <h1 className="text-[40px] font-bold text-white mb-4">One Of Us?</h1>
  
        <h5 className="text-[24px] text-white">If you already have an account, just login. </h5>
        <div className="mt-16">
          <button
            className="py-3 px-6 border border-[#FEFEFE] bg-transparent rounded-md text-center text-white font-bold uppercase transition-transform ease-in"
            onClick={(e) => {
              setIsAnimated(!isAnimated);
            }}
          >
            Sign In
          </button>
        </div>
      </div>
    );
  };

  
  export default LeftOverlayContent;
  