import React from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../redux/Slices/dialogSlice';

function TokenDialogue() {
    const dispatch = useDispatch();

    return (
        <div className="text-center">
            <h1 className="text-red-500 text-2xl mt-6 mb-4">To view the tests, please log in or sign up.</h1>
            <button
                className="text-primaryColor underline font-bold text-2xl cursor-pointer focus:outline-none"
                onClick={() => dispatch(openDialog("signIn"))}
            >
                LOGIN
            </button>
        </div>
    );
}

export default TokenDialogue;
