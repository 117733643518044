import React, { ChangeEvent } from "react";
import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";

const LatexUtility = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };
 
  return (
    <div className="flex justify-center  ">
      <div className="flex flex-col p-10">
        <textarea
          className="border rounded-lg border-gray-500 flex justify-center items-center mt-6 p-6"
          value={value}
          onChange={handleChange}
          spellCheck={false}
          placeholder="Enter the Maths Equation Here...."
        />
        <span className="flex justify-center mb-8 mt-3">
          <span className="mt-2">Maths Equation from the User is: </span> &nbsp;
          &nbsp; <TeX >{value}</TeX>
        </span>
        <div className="text-base font-semibold">
          Static Maths Equation displayed from the Code:
        </div>
        <p>
          find the value of the integral <TeX>\int_0^\infty x^5 dx</TeX> and 
          <TeX>\int_0^\infty x^4 dx</TeX> also <TeX>{`az + b\\bar{z} = c`}</TeX> ?
        </p>
      </div>
    </div>
  );
};

export default LatexUtility;
