import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFullTestState } from "../redux/Selectors/selectorsUse";
import ErrorPage from "./ErrorPage";
import Loader from "../components/ReUsableComponents/Loader";
import CustomButton from "../components/ReUsableComponents/CustomButton";
import { useNavigate } from "react-router-dom";
import { useGetAllCompletedTestQuery } from "../redux/API/StartTest/startTestApi";
import { setTestName } from "../redux/Slices/startFullTestSlice";
import LogoutHeader from "../components/ReUsableComponents/LogoutHeader";

const TestResultsList = () => {
  const { startTestSlice, loadingSlice } = useSelector(selectFullTestState);
   useGetAllCompletedTestQuery({});
   const dispatch = useDispatch();
   const {completedTestData:testData} = startTestSlice
  const { isLoading, isError } = loadingSlice;
  const navigate = useNavigate();

useEffect(() =>{
    localStorage.removeItem("overallResults");
    localStorage.removeItem("isScoreCaluculated");
  },[]);
  if (isError) {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  if (isLoading) {
    return <div className="flex justify-center items-center w-full h-screen">
    <Loader />
  </div>
  }
  return (
    <div className="w-1/2 mx-auto">
    <div className="my-4 mb-5">
    <LogoutHeader />
    <div className="border border-b-4 font-bold border-black opacity-80 rounded my-2 w-1/6"></div>
    </div>
      <p className="font-bold  text-2xl text-primaryColor  relative my-2">
        Test Results
      </p>
      {testData.length > 0 ?
      (
        <div className="flex flex-wrap gap-4">
          {testData.map((item: any, index: any) => {
            return (
              <div
                key={item.testId}
                className={`border w-80  rounded-xl  px-8 py-4 flex justify-between gap-2`}
              >
                <div className="font-semibold text-base mb-3 "> {item.name}</div>

                <div className="border"></div>
                <div className=" flex flex-col gap-4">
                <div className=" text-base"> <span className="text-[#8C8C8C] pr-3">My Score</span> <span className="text-primaryColor font-semibold">{item?.additionalProps?.overallresult?.overallFinaleScaledScore}</span></div>
                <CustomButton
                  name={"View Result"}
                  bg={ "bg-white"}
                  onClickHandler={() => {
                    localStorage.setItem("isScoreCaluculated","true");
                    localStorage.setItem("currentTestName",item.name);
                    dispatch(setTestName(item.name));
                    if(item.testId){
                        navigate(`/practices/results/${item.testId}`)
                    }else{
                        console.log("TEST ID getting undefined")
                    }
                  }}
                  py="py-2"
                  width="w-full"
                  text="text-sm"
                  round="xl"
                  color={"primaryColor"}
                  font = "font-semibold"
                  border={ "border-primaryColor"}
                ></CustomButton>
                </div>
                
              </div>
            );
          })}
        </div>
      ) : <div className="flex justify-center items-center text-center font-semibold text-gray-500">No Test Results Found</div>}
    </div>
    
  );
};

export default TestResultsList;
