import React from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { useNavigate } from "react-router-dom";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import {
  useGetAllMockTestsQuery,
  useSaveAndExitTestMutation,
} from "../../redux/API/StartTest/startTestApi";
import Loader from "../ReUsableComponents/Loader";

const SaveAndExit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startTestSlice, resultSlice, loadingSlice } =
    useSelector(selectFullTestState);
  const [saveAndExitTest] = useSaveAndExitTestMutation({});
  const { refetch } = useGetAllMockTestsQuery({});

  const continueTest = () => {
    dispatch(closeDialog());
  };

  const exitTest = async () => {
    let savePayload = {
      userTestId: startTestSlice.userTestId,
      testId: startTestSlice.testId,
      questionData: {
        Mathematics: {
          Module1: startTestSlice.mathModuleOneQuestions,
          Module2: startTestSlice.mathModuleTwoQuestions,
        },
        ReadingAndWriting: {
          Module1: startTestSlice.readingModuleOneQuestions,
          Module2: startTestSlice.readingModuleTwoQuestions,
        },
      },
      answerData: {
        Mathematics: {
          Module1: resultSlice.mathsModuleOneAnswers,
          Module2: resultSlice.mathsModuleTwoAnswers,
        },
        ReadingAndWriting: {
          Module1: resultSlice.writingModuleOneAnswers,
          Module2: resultSlice.writingModuleTwoAnswers,
        },
      },
      additionalProps: {
        currentModuleName: startTestSlice.currentModuleName,
        count: startTestSlice.count,
        currentTime: startTestSlice.currentTime,
      },
      testCompleted: false,
      overallTestResult:startTestSlice.overallresult
    };
    await saveAndExitTest(savePayload);
    refetch();
    dispatch(closeDialog());
    navigate("/practices");
    //dispatch(clearAnswers())
  };

  return (
    <div className="flex flex-col">
      <div className="text-[#202020] text-lg">
        If you exit now, we will save your progress and you can resume this test
        any time.
      </div>
      {loadingSlice.isLoading ? (
       <div className="flex justify-center ">
       <Loader />
     </div>
      ) : (
        <div className="flex justify-end mt-8">
          <CustomButton
            name="Continue Test"
            onClickHandler={continueTest}
            bg="bg-white"
            font="font-semibold"
            border="border-primaryColor"
            mx="mx-2"
            color="primaryColor"
          />
          <CustomButton
            name="Save and Exit"
            onClickHandler={exitTest}
            bg="bg-primaryColor"
            font="font-semibold"
            color="white"
          />
        </div>
      )}
    </div>
  );
};

export default SaveAndExit;
