import React, { useCallback, useEffect } from 'react'
import CustomButton from '../ReUsableComponents/CustomButton';
import CommonTestModuleLayout from '../ReUsableComponents/CommonTestModuleLayout';
import { changeCurrentModuleName, setCount, setCurrentTime, setIsPopUp, setSpentTime } from '../../redux/Slices/startFullTestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../redux/Slices/dialogSlice';
import { selectFullTestState } from '../../redux/Selectors/selectorsUse';
import { next } from '../../utils/commonLogic';
import { RootState } from '../../redux/Store/store';
import { getItem } from '../../utils/token';

const WritingModuleTwo = () => {
    const dispatch = useDispatch();
    const {startTestSlice,resultSlice} = useSelector(selectFullTestState);
    const currentTime = useSelector((state:RootState) => state.root.startFullTest.currentTime);
    const saveHandler = () => {
      dispatch(openDialog("writingInstructions"));
      };
    
      const heading = useCallback(() => {
        return (
          <div className="flex flex-col">
            <div className="text-lg font-semibold pb-2">
              Reading and Writing: Module 2
            </div>
          </div>
        );
      },[]);
      const timer = () => {
        return <div>19:25</div>;
      };
      const exit = () => {
        return (
          <div>
            <CustomButton
              name="Save & Exit"
              onClickHandler={saveHandler}
              bg="bg-white"
              color="[#333]"
              font="font-bold"
              width="w-full"
              border="border-[#333]"
              py="py-2"
              round="full"
            />
          </div>
        );
      };
      const children = () => {
        return <div className="flex justify-center items-center">Writing Test Module TWO Children Component </div>;
      };
      const textName = () => {
        return <div className="font-semibold">  {getItem('currentTestName')} </div>;
      };
      const nexxtClickHandler = () => {
        if (startTestSlice.count > startTestSlice.readingModuleTwoQuestions.length-1) {
          dispatch(setSpentTime({ field: 'readingAndWritingModuleTwoSpentTime', value: +currentTime }))
          dispatch(changeCurrentModuleName("moduleBreakTime"));
          dispatch(setCount(""));
          dispatch(setCurrentTime(600))
        } else {
          dispatch(setCount("Increment"));
        }
        dispatch(setIsPopUp(false))
      };
    
     
      useEffect(() => {
        dispatch(changeCurrentModuleName("writingModule2"))
        dispatch(openDialog("writingInstructions"));
      },[])

      useEffect(() => {
        if(currentTime <= 0){
          dispatch(changeCurrentModuleName("writingModule2"));
          dispatch(setCurrentTime(600))
          dispatch(setCount(""));
        }
      },[currentTime])

      return (
        <CommonTestModuleLayout
          heading={heading}
          timer={timer}
          exit={exit}
          textName={textName}
          next={() =>next(nexxtClickHandler)}
          questionsList={startTestSlice.readingModuleTwoQuestions}
          moduleName={"writingModuleTwoAnswers"}
          userAnswers ={resultSlice.writingModuleTwoAnswers}
        >
          {children}
        </CommonTestModuleLayout>
      );
}

export default WritingModuleTwo