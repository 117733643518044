import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import { ReactNode, Suspense, lazy } from "react";
import CustomDialog from "./components/ReUsableComponents/CustomDialog";
import { useSelector } from "react-redux";
import { RootState } from "./redux/Store/store";
import ErrorPage from "./pages/ErrorPage";
import FullTest from "./pages/FullTest";
import ModuleOneInstructions from "./components/DialogComponents/ModuleOneInstructions";
import TestInstructions from "./components/DialogComponents/StartTestInstructions";
import ExamCompletedCard from "./pages/ExamCompletedCard";
import SaveAndExit from "./components/DialogComponents/SaveAndExit";
import ModuleTwoInstructions from "./components/DialogComponents/ModuleTwoInstructions";
import LatexUtility from "./pages/LatexUtility";
import SignUp from "./pages/LogInPage/SignUp";
import TokenDialogue from "./pages/TokenDialogue";
import LogIn from "./pages/LogInPage/LogIn";
import TestResultsList from "./pages/TestResultsList";
import ResultsDialog from "./components/ReUsableComponents/ResultsDialog";
import ReferenceMathsEquations from "./pages/ReferenceMathsEquations";
import Loader from "./components/ReUsableComponents/Loader";
import ExplanationInBank from "./components/DialogComponents/ExplanationInBank";

const PracticeTests = lazy(() => import("./pages/PracticeTests"));
const QuestionBank = lazy(() => import("./pages/QuestionBank"));
const Pricing = lazy(() => import("./pages/Pricing"));
const AboutUs = lazy(() => import("./pages/About"));
const TransactionPage = lazy(() => import("./pages/TransactionSummary"))
const QuestionPageINQuestionBank = lazy(() => import("./pages/QuestionPageInBank"))




type DialogComponentType = {
  [key: string]: ReactNode;
};

const App = () => {
  const dialogType = useSelector((state: RootState) => state.root.dialog.dailogType);

  const DIALOG_TITLES:any = {
    signIn: "",
    // Add other dialog titles here
  };
  
  const DIALOG_WIDTHS:any = {
    writingInstructions: "max-w-6xl",
    mathsInstructions: "max-w-6xl",
    resultsExplaination:"max-w-7xl",
    default: "max-w-lg",
  };
  
  const getDialogTitle = (type:any) => DIALOG_TITLES[type] || "";
  
  const getDialogWidth = (type:any) => DIALOG_WIDTHS[type] || DIALOG_WIDTHS.default;

  const dialogComponent: DialogComponentType = {
    signIn: <SignUp isAnimated={false} setIsAnimated={() =>{}}/>,
    tokenDialogue:<TokenDialogue/>,
    startTest: <TestInstructions />,
    writingInstructions:<ModuleOneInstructions />,
    saveExitDialogue:<SaveAndExit />,
    mathsInstructions:<div ><ModuleTwoInstructions /></div>,
    resultsExplaination:<div><ResultsDialog /></div>,
    explanationQuestionInBank:<div><ExplanationInBank /></div>
  };

  return (
    <div>
      <Router>
        <Suspense fallback={<div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>}>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
             
              {/* <Route index element={<Home />} /> */}
              <Route path="home" element={<Home />} />
              <Route path="practices"  element={<PracticeTests />} />
              <Route path="practices/results/:id" element={<ExamCompletedCard />} />
              <Route path="practices/results" element={<TestResultsList />} />
              <Route path="categories" element={<QuestionBank />} />
              <Route path="products" element={<Pricing />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="transactionsummary" element={<TransactionPage />} />
              <Route path="latexPageValidation" element={<LatexUtility />}/>
            </Route>
            <Route
              path="/practices/:id"
              element={<FullTest />}
            />
            <Route path="categories/:id" element={<QuestionPageINQuestionBank />} />
             <Route
              index
              element={<LogIn />}
            />
            <Route
              index
              path="/login"
              element={<LogIn />}
            />
            
            <Route path='/references' element={<ReferenceMathsEquations />}/>
            <Route path='*' element={<ErrorPage />}/>
          </Routes>
        </Suspense>
        <CustomDialog
      dialogTitle={getDialogTitle(dialogType)}
      maxWidth={getDialogWidth(dialogType)}
    >
      {dialogType && dialogComponent[dialogType]}
    </CustomDialog>
      </Router>
    </div>
  );
};

export default App;
