import {getItem} from './utils/token';

let be_url = "https://staging.sat.api.cambri.ai/api";

const get_profile = () => {
    const host = window.location.host;
if(host === "business.sat.cambri.ai") return "production"
if(host === "staging.sat.cambri.ai") return "stagging"
if(host === "localhost:3000") return "dev"
return "unknown"

}
const profile = get_profile();
switch (profile) {
    case "dev":
      be_url = "https://dev.api.sat.cambri.ai/api";
      break;

    case "stagging" :
      be_url = "https://dev.api.sat.cambri.ai/api";
      break;
    case "production":
      be_url = "https://sat.api.cambri.ai/api";
      break;
  
    default:
      console.warn(`Unknown profile: ${profile}`);
      break;
  }
  
  export const serviceUrl = be_url;
  export const genericHeaders = () => {
    if (getItem('token') != null) {
      return {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getItem("token"),
      };
    }
    return {
      "Content-Type": "application/json",
    };
  }; 
