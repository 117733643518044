import React from "react";

const CustomTextField = ({
  label,
  type,
  placeholder,
  onChangeHandler,
  value,
  name,
  mandatory
}: {
  type: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder: string;
  value?: string;
  name: string;
  mandatory?:boolean;
}) => {
  return (
    <div className="flex flex-col mt-[18px]">
      <label className=" lg:text-sm md:text-[0.75rem] text-[0.75rem] mb-[5px] text-primaryColor">{label}{mandatory&&<span className="text-red-600 text-base font-normal">*</span>}</label>
      <input
        className="border border-[#E3E5E9] lg:p-2 md:-pb-[1rem] -pb-[0.5rem] rounded-lg my-input mr-4"
        type={type}
        placeholder={placeholder}
        onChange={onChangeHandler}
        value={value}
        name={name}
      />
    </div>
  );
};

export default CustomTextField;
